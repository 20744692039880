import React, {useState} from 'react';

import Typography, {VARIANT} from './../../../atoms/Typography/Typography';
import Content from './../../../atoms/Content/Content';
import {PrimaryButton} from './../../../atoms/Button/Button';
import Modal from './../../../atoms/Modal/Modal';

import Form from './../../Forms/Form';

import Row from './../../../layouts/Row/Row';
import Column from './../../../layouts/Column/Column';
import FullRow from './../../../layouts/FullRow/FullRow';

import './CmsForm.scss';

const CmsForm = ({content, scrollAnimation}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {title, description, form} = content;

  return (
    <Row className="cms-form" scrollAnimation={scrollAnimation}>
      <Column className="cms-form__column">
        {title && <Typography variant={VARIANT.HEADING}>{title}</Typography>}
        {description && (
          <Content className="cms-form__description" raw>
            {description}
          </Content>
        )}
        {form[0] && (
          <div className="cms-form__form">
            <PrimaryButton className="cms-form__form-button" onClick={() => setIsOpen(true)}>
              <Typography variant={VARIANT.BODY_SMALL}>{form[0].buttonTitle}</Typography>
            </PrimaryButton>
            <FullRow>
              <Column>
                <Modal
                  className="expert-module__modal"
                  fullHeight={true}
                  isOpen={isOpen}
                  onCloseClick={() => setIsOpen(false)}
                >
                  <Form formInfo={form[0]} />
                </Modal>
              </Column>
            </FullRow>
          </div>
        )}
      </Column>
    </Row>
  );
};

export default CmsForm;
