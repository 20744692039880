import React from 'react';
import {compose} from 'redux';
import {injectIntl} from 'react-intl';

import RoutingGenericPreview from './../../_bridges/RoutingGenericPreview/RoutingGenericPreview';
import {getListRoute, getPostType} from './../../_util/routes';
import {getPostTypeSingularLabel} from './../../_util/labels';
import {getRelNext, getRelPrev} from './../../_util/pagination';
import withLocationPage from './../../_hocs/withLocationPage';

import Helmet from './../../seo/Helmet';

import Loader from './../../atoms/Loader/Loader';
import Typography from './../../atoms/Typography/Typography';

import Paginator from './../../components/Paginator/Paginator';

import GenericHero from './../../compositions/GenericHero/GenericHero';

import FullRow from './../../layouts/FullRow/FullRow';
import Column from './../../layouts/Column/Column';
import Grid from './../../layouts/Grid/Grid';
import Row from './../../layouts/Row/Row';

import './GenericContainer.scss';

const formatListRoute = (locale, type) => page => getListRoute(type, page, locale, {});

const GenericContainer = ({content, title, image, intl, pagination, page, type, fetching}) => {
  const listBaseUrl = getListRoute(type, 0, intl.locale, {});
  return (
    <div className="generic-container">
      <GenericHero backgroundImage={image} title={title} type={title} />
      <Helmet
        relNext={getRelNext(page, pagination.totalPages, listBaseUrl)}
        relPrev={getRelPrev(page, listBaseUrl)}
        title={title}
      />
      <Row className="generic-container__content">
        <Column className="generic-container__content-main">
          {!fetching ? (
            content.length > 0 ? (
              <Grid
                className="generic-container__grid"
                columnsPerBreakpoint={{S: 1, M: 3, XXL: 4}}
                scrollAnimation={false}
                tag={FullRow}
              >
                {content.map((card, index) => (
                  <RoutingGenericPreview
                    key={index}
                    label={intl.formatMessage({id: getPostTypeSingularLabel(card.type)})}
                    preview={card}
                    to={getPostType(card.type)}
                  />
                ))}
              </Grid>
            ) : (
              <Typography className="generic-container__non-content">
                {intl.formatMessage({id: type === 'pressReleases' ? 'list.press.release.none' : 'list.news.none'})}
              </Typography>
            )
          ) : (
            <Loader />
          )}
          {pagination.totalPages > 1 && (
            <Paginator
              currentPage={page}
              formatPageRoute={formatListRoute(intl.locale, type)}
              totalPages={pagination.totalPages}
            />
          )}
        </Column>
      </Row>
    </div>
  );
};

export default compose(React.memo, injectIntl, withLocationPage)(GenericContainer);
