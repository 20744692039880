import React from 'react';

import './LandingLogos.scss';
import Picture from '../../../atoms/Picture/Picture';

const LandingLogos = ({label, labelColor, logos}) => {
  const labelStyle = {
    color: labelColor,
  };
  return (
    <div className="landing-logos__top-wrapper">
      {label && (
        <h2 className="fs--label" style={labelStyle}>
          {label}
        </h2>
      )}
      <div className="landing-logos__wrapper">
        {logos.map(item => (
          <div className="landing-logos__logo-wrapper">
            <div className="landing-logos__logo">
              <Picture
                alt={item.image.alt}
                asyncLoad={false}
                className="landing-logos__logo-image"
                cover={false}
                imageClassName=""
                pictureClassName=""
                src={item.image.src.large}
                srcSet={[{src: item.image.src.small}, {src: item.image.src.medium}]}
              />
              <p className="fs--sm landing-logos__logo-text">{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LandingLogos;
