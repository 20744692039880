import backendPageGet from './../../api/Backend/Page';
import backendTemplateGet from './../../api/Backend/Template';
import backendContextGet from './../../api/Backend/Context';
import backendRedirectGet from './../../api/Backend/Redirect';
import {API_FETCHING, API_RECEIVED, API_FAILED, API_CANCELED} from './ApiReducer';

import isSSR from './../../config/isSSR';
import ClientRequestQueue from './ClientRequestQueue';
import {httpRequestError} from './../Error/ErrorActions';

const backendResource = (type, method, locale) => ({
  key,
  keyFilter,
  params,
  currentCustomRouteAndPath,
} = {}) => async dispatch => {
  dispatch({
    type: API_FETCHING,
    resourceType: type,
    currentCustomRouteAndPath: currentCustomRouteAndPath,
  });
  try {
    const request = method(params);
    if (!isSSR() && keyFilter) {
      ClientRequestQueue.cancelNonMatchingKeyRequests({request, key, keyFilter});
    }
    const resource = await request;
    dispatch({
      type: API_RECEIVED,
      resourceType: type,
      resource: resource.data,
    });
  } catch (error) {
    if (error.message && error.message.cancelled) {
      dispatch({
        type: API_CANCELED,
        resourceType: type,
      });

      return;
    }

    dispatch({
      type: API_FAILED,
      resourceType: type,
    });

    if (error.response) {
      const redirectResponse = await backendRedirectGet(locale).oneBySlug({locale: locale, slug: params.slug});
      const redirect = redirectResponse.data.match ? redirectResponse.data.redirect : '';
      dispatch(httpRequestError(type, locale)({...error.response, redirect: redirect}));
    } else {
      dispatch(
        httpRequestError(type, locale, params ? params.slug : '')({status: 503, data: {message: error.toString()}}),
      );
    }
  }
};

export const getBackendPage = locale => backendResource('backendPage', backendPageGet(locale).byPath, locale);
export const getBackendContext = locale => backendResource('backendContext', backendContextGet(locale).all, locale);
export const getBackendTemplate = locale =>
  backendResource('backendTemplate', backendTemplateGet(locale).byPath, locale);
