import React from 'react';
import {compose} from 'redux';

import withSize from './../../../_packages/resize/ui/withSize';

import './LandingFooter.scss';
import Picture from './../../atoms/Picture/Picture';

const Footer = () => (
  <footer className="footer column">
    <div className="footer__top-wrapper">
      <div className="footer__logo">
        <Picture alt="tecnalia-logo" src="/images/logo-bw-white.svg" />
      </div>
      <div className="footer__contact">
        <h3 className="fs--label">Contáctanos</h3>
        <div className="footer__location">
          <h4 className="fs--body footer__heading">Parque Científico y Tecnológico de Gipuzkoa</h4>
          <div className="footer__address">
            <span>Mikeletegi Pasealekua, 2</span>
            <span>E-20009 Donostia - San Sebastián (Gipuzkoa)</span>
          </div>
        </div>
        <div className="footer__phone">
          <span>
            Tel.:{' '}
            <a className="footer__phone__number" href="tel:94643085">
              946 430 850
            </a>
          </span>
          <span>
            Tel. internacional:{' '}
            <a className="footer__phone__number" href="tel:+34946430850">
              (+34) 946 430 850
            </a>
          </span>
        </div>
      </div>
      <div className="footer__collaborators">
        <Picture
          alt="tecnalia-logo"
          className="footer__collaborator footer__collaborator--brta"
          src="/images/logo-brta.svg"
        />
        <Picture
          alt="tecnalia-logo"
          className="footer__collaborator footer__collaborator--earto"
          src="/images/logo-earto.png"
        />
        <Picture
          alt="tecnalia-logo"
          className="footer__collaborator footer__collaborator--euskampus"
          src="/images/logo-euskampus.svg"
        />
      </div>
    </div>
    <div className="fs--sm footer__bottom-wrapper">
      <div className="footer__copyright">© 2024 TECNALIA. Todos los derechos reservados.</div>
      <ul className="footer__legal-links">
        <li>
          <a
            className="footer__legal-link footer__legal-link--first"
            href="https://www.tecnalia.com/politica-de-privacidad"
          >
            Política de privacidad
          </a>
        </li>
        <li>
          <a className="footer__legal-link" href="https://www.tecnalia.com/politica-de-cookies">
            Política de cookies
          </a>
        </li>
        <li>
          <a className="footer__legal-link" href="https://www.tecnalia.com/informacion-legal">
            Información Legal
          </a>
        </li>
        <li>
          <a
            className="footer__legal-link footer__legal-link--last"
            href="https://channel.globalsuitesolutions.com/tecnalia"
          >
            Canal ético
          </a>
        </li>
      </ul>
    </div>
  </footer>
);

export default compose(withSize, React.memo)(Footer);
