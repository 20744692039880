import {withI18nRouting} from '@foes/react-i18n-routing';
import classNames from 'classnames';
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';

import {INSPIRING_BLOG_CATEGORY, INSPIRING_BLOG_TAG} from '../../../../routing/inspiringBlog/routes';
import withSize from './../../../../_packages/resize/ui/withSize';
import i18n from './../../../../i18n';

import './LandingLanguageSelector.scss';

const translatedSlugsForLocales = (locales, extraData) => translatedSlugs =>
  locales.map(locale => ({
    ...(extraData && extraData[locale]),
    locale: locale,
    slug: translatedSlugs && translatedSlugs[locale] ? translatedSlugs[locale] : '',
  }));

const translatedSlugsForAppLocales = translatedSlugsForLocales(i18n.locales);

const routerForTemplate = {
  inspiringBlogCategory: INSPIRING_BLOG_CATEGORY,
  inspiringBlogTag: INSPIRING_BLOG_TAG,
};

const translatedSlugsProvider = ({i18nRouting, slug, template, locale}) =>
  ['inspiringBlogCategory', 'inspiringBlogTag'].includes(template)
    ? i18nRouting.formatIntlRoute(routerForTemplate[template], {slug: slug}, locale)
    : i18n.prependLocale(locale, slug);
const LocaleItem = ({children, i18nRouting, locale, selectedLocale, slug, template}) => (
  <a
    className={classNames('landing-language-selector-option', {
      'landing-language-selector-option--selected': selectedLocale === locale,
    })}
    href={translatedSlugsProvider({slug, template, locale, i18nRouting})}
  >
    {children}
  </a>
);
const LandingLanguageSelector = ({currentLocale, mode, template, translatedSlugs, i18nRouting}) => (
  <React.Fragment>
    <div
      className={`fs--body landing-language-selector landing-language-selector--${mode}`}
      onTouchEnd={() => true}
      onTouchStart={() => true}
    >
      {translatedSlugsForAppLocales(translatedSlugs).map(({locale, slug}) => (
        <LocaleItem
          i18nRouting={i18nRouting}
          key={locale}
          locale={locale}
          selectedLocale={currentLocale}
          slug={slug}
          template={template}
        >
          <span className="fs--body">{locale}</span>
        </LocaleItem>
      ))}
    </div>
  </React.Fragment>
);

const mapStateToProps = state => ({
  translatedSlugs:
    state.api.backendPage && state.api.backendPage.resource
      ? state.api.backendPage.resource.translatedSlugs
      : i18n.defaultTranslatedRoutes,
  template: state.api.backendPage && state.api.backendPage.resource ? state.api.backendPage.resource.template : '',
});

export default compose(connect(mapStateToProps), withSize, React.memo, withI18nRouting)(LandingLanguageSelector);
