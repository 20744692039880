import React from 'react';
import {compose} from 'redux';
import {injectIntl} from 'react-intl';
import get from 'lodash.get';

import {DELEGATION} from './../../../routing/delegations/routes';

import RoutingGenericPreview from './../../_bridges/RoutingGenericPreview/RoutingGenericPreview';
import RoutingFeaturedPreviewCard from './../../_bridges/RoutingFeaturedPreviewCard/RoutingFeaturedPreviewCard';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import DelegationsHero from './DelegationsHero/DelegationsHero';

import MainLayout from './../../layouts/MainLayout/MainLayout';
import Row from './../../layouts/Row/Row';
import Column from './../../layouts/Column/Column';
import Grid from './../../layouts/Grid/Grid';

import './Delegations.scss';

const getDelegationMarkers = delegations => delegations.map(delegation => get(delegation, 'googleMaps.map'));

const Delegations = ({canonical, content: places, title, intl}) => {
  const reduceDelegations = places.reduce((acc, item) => [...acc, ...item.items], []);
  const delegationsMarkers = getDelegationMarkers(reduceDelegations);
  const mainDelegation = places.filter(place => place.type === 'main')[0];
  const delegations = places.filter(place => place.type === 'delegation')[0].items;
  const offices = places.filter(place => place.type === 'office')[0].items;
  return (
    <div className="delegations">
      <DelegationsHero delegationsMarkers={delegationsMarkers} title={title} type={title} />
      <MainLayout
        canonical={canonical}
        mainContent={
          <React.Fragment>
            <Typography className="delegations__central-title" tag="h2" variant={VARIANT.HEADING}>
              {intl.formatMessage({id: 'delegations.central'})}
            </Typography>
            <Row>
              <Column className="delegations__central-column">
                <RoutingFeaturedPreviewCard
                  image={mainDelegation.items[0].image}
                  intl={intl}
                  slug={mainDelegation.items[0].slug}
                  text={mainDelegation.items[0].excerpt}
                  title={mainDelegation.items[0].title}
                  type={mainDelegation.items[0].type}
                  wholeCardIsLink
                />
              </Column>
            </Row>
          </React.Fragment>
        }
      />
      <Row className="delegations__delegations">
        <Column className="delegations__delegations-column">
          <Typography tag="h2" variant={VARIANT.HEADING}>
            {intl.formatMessage({id: 'delegations.delegations'})}
          </Typography>
          <Grid className="delegations__delegations-grid" columnsPerBreakpoint={{S: 1, M: 2, XL: 3, XXXXL: 4}}>
            {delegations.map((delegation, index) => (
              <RoutingGenericPreview
                content={
                  delegation.cityProvince.cityProvince && (
                    <React.Fragment>
                      <Typography tag="p">—</Typography>
                      <Typography>{delegation.cityProvince.cityProvince}</Typography>
                    </React.Fragment>
                  )
                }
                key={index}
                label={intl.formatMessage({id: 'delegation.category'})}
                preview={delegation}
                to={DELEGATION}
              />
            ))}
          </Grid>
        </Column>
      </Row>

      <Row className="delegations__offices">
        <Column className="delegations__offices-column">
          <Typography tag="h2" variant={VARIANT.HEADING}>
            {intl.formatMessage({id: 'delegations.offices'})}
          </Typography>
          <Grid className="delegations__delegations-grid" columnsPerBreakpoint={{S: 1, M: 2, XL: 3, XXXXL: 4}}>
            {offices.map((office, index) => (
              <RoutingGenericPreview
                content={
                  office.cityProvince.cityProvince && (
                    <React.Fragment>
                      <Typography tag="p">—</Typography>
                      <Typography>{office.cityProvince.cityProvince}</Typography>
                    </React.Fragment>
                  )
                }
                key={index}
                label={intl.formatMessage({id: 'delegation.office.category'})}
                preview={office}
                to={DELEGATION}
              />
            ))}
          </Grid>
        </Column>
      </Row>
    </div>
  );
};

export default compose(React.memo, injectIntl)(Delegations);
