import React from 'react';
import classNames from 'classnames';

import RoutingCms5050 from './../../_bridges/RoutingCms5050/RoutingCms5050';
// eslint-disable-next-line max-len
import RoutingCmsRelatedCertifications from './../../_bridges/RoutingCmsRelatedCertifications/RoutingCmsRelatedCertifications';

import Picture from './../../atoms/Picture/Picture';
import {VARIANT} from './../../atoms/Typography/Typography';

import TitleTextComponent from './../../components/TitleTextComponent/TitleTextComponent';
import TitleComponent from './../../components/TitleComponent/TitleComponent';
import CmsVideo from './../../components/CmsComponents/CmsVideo/CmsVideo';
import CmsFeaturedLinkGrid from './../../components/CmsComponents/CmsFeaturedLinkGrid/CmsFeaturedLinkGrid';
import CmsGrid from './../../components/CmsComponents/CmsGrid/CmsGrid';
import CmsAccordion from './../../components/CmsComponents/CmsAccordion/CmsAccordion';
import CmsForm from './../../components/CmsComponents/CmsForm/CmsForm';
import ContentGridList from './../../compositions/ContentGridList/ContentGridList';
import ContentGrid from './../../compositions/ContentGrid/ContentGrid';
import CmsDivider from './../../components/CmsComponents/CmsDivider/CmsDivider';
import CmsAccordionContent from './../../components/CmsComponents/CmsAccordionContent/CmsAccordionContent';

import Row from './../../layouts/Row/Row';
import Column from './../Column/Column';

import './GenericItems.scss';

const PageItemsToComponent = ({items, scrollAnimation}) =>
  items &&
  items.map((item, index) => {
    if (item.type === 'flexible_title_list') {
      return (
        <ContentGridList
          items={item.values.items}
          key={index}
          title={item.values.title}
          titleVariant={VARIANT.HEADING}
        />
      );
    }
    if (item.type === 'flexible_title_text') {
      return (
        <Row key={index} scrollAnimation={scrollAnimation}>
          <Column className="flexible-title-text__column">
            <TitleTextComponent
              biggerTitle
              id={item.values.id}
              raw
              text={item.values.text}
              textVariant={item.values.small ? VARIANT.BODY_SMALLER : VARIANT.BODY}
              title={item.values.title}
            />
          </Column>
        </Row>
      );
    }
    if (item.type === 'flexible_title_text_two_columns') {
      return <ContentGrid items={item.values.items} key={index} title={item.values.title} />;
    }
    if (item.type === 'flexible_image_text_block') {
      return (
        <RoutingCms5050
          content={item.values}
          inRow
          key={index}
          reverse={item.values.position === 'left'}
          scrollAnimation={scrollAnimation}
        />
      );
    }
    if (item.type === 'flexible_video_block') {
      return <CmsVideo key={index} scrollAnimation={scrollAnimation} video={item.values.url} />;
    }
    if (item.type === 'flexible_file_field') {
      return <CmsFeaturedLinkGrid content={item.values} key={index} scrollAnimation={scrollAnimation} />;
    }
    if (item.type === 'flexible_grid_block') {
      return <CmsGrid content={item.values} key={index} scrollAnimation={scrollAnimation} />;
    }
    if (item.type === 'flexible_accordion') {
      return <CmsAccordionContent content={item.values} key={index} />;
    }
    if (item.type === 'flexible_accordion_block') {
      return <CmsAccordion content={item.values} key={index} />;
    }
    if (item.type === 'flexible_related_certification_field') {
      return <RoutingCmsRelatedCertifications content={item.values} key={index} />;
    }
    if (item.type === 'flexible_title_field') {
      return (
        <TitleComponent
          id={item.values.id}
          key={index}
          scrollAnimation={scrollAnimation}
          small={item.values.small}
          title={item.values.title}
        />
      );
    }
    if (item.type === 'flexible_form_selector') {
      return <CmsForm content={item.values} key={index} scrollAnimation={scrollAnimation} />;
    }
    if (item.type === 'flexible_divider') {
      return <CmsDivider key={index} />;
    }
    if (item.type === 'flexible_image_block') {
      return (
        <Picture
          alt={item.values.image.alt || item.values.image.title}
          caption={item.values.image.caption}
          className="image-block__picture-image"
          cover
          key={index}
          showCaption={item.values.image.showCaption}
          src={item.values.image.src.medium}
          srcSet={[
            {
              src: item.values.image.src.small,
              maxWidth: 480,
            },
          ]}
        />
      );
    }
    return null;
  });

const GenericItems = ({className, items, scrollAnimation, ...rest}) => (
  <div className={classNames('generic-items', className)} {...rest}>
    {items && <PageItemsToComponent items={items} scrollAnimation={scrollAnimation} />}
  </div>
);

GenericItems.defaultProps = {
  scrollAnimation: true,
};

export default React.memo(GenericItems);
