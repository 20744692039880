import React, {useState} from 'react';

import Typography, {VARIANT} from './../../../atoms/Typography/Typography';
import {PrimaryButton} from './../../../atoms/Button/Button';
import Modal from './../../../atoms/Modal/Modal';

import CmsLink from './../../../components/CmsLink/CmsLink';

import Form from './../../../components/Forms/Form';

import Column from './../../../layouts/Column/Column';
import Row from './../../../layouts/Row/Row';
import FullRow from './../../../layouts/FullRow/FullRow';

import './ContactModule.scss';

const ContactModule = ({content}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Row className="contact-module" tag="section">
      <Column className="contact-module__column">
        <div className="contact-module__content">
          <Typography className="contact-module__content-category" tag="h3" variant={VARIANT.HEADING_SMALL}>
            {content.subtitle}
          </Typography>
          <Typography tag="h2" variant={VARIANT.H1}>
            {content.title}
          </Typography>
          <Typography className="contact-module__content-text" tag="p" variant={VARIANT.BODY}>
            {content.description}
          </Typography>
          <div className="contact-module__buttons">
            <CmsLink className="contact-module__button" slug={content.linkUrl}>
              <PrimaryButton>
                <Typography variant={VARIANT.BODY_SMALL}>{content.linkTitle}</Typography>
              </PrimaryButton>
            </CmsLink>
            <PrimaryButton className="contact-module__button" onClick={() => setIsOpen(true)}>
              <Typography variant={VARIANT.BODY_SMALL}>{content.linkTitle2}</Typography>
            </PrimaryButton>
          </div>
        </div>
      </Column>
      <FullRow>
        <Column>
          <Modal
            className="expert-module__modal"
            fullHeight={true}
            isOpen={isOpen}
            onCloseClick={() => setIsOpen(false)}
          >
            <Form formUrl={content.linkUrl2} title={content.linkTitle2} type="footer_contact" />
          </Modal>
        </Column>
      </FullRow>
    </Row>
  );
};

export default React.memo(ContactModule);
